import { KokattoServiceClient } from "@/serviceClients/KokattoServiceClient";
import { KokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";
import axios from "axios";
import { KokattoPostLogResponse } from "@/serviceClients/responses/KokattoLoggingResponse";
import { KokattoPostLogRequest } from "./requests/KokattoPostLogRequest";
import { getUserDetailFromJwt } from "@/utils/jwtUtil";

export class KokattoLoggingServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL ?? "";
    }
  }

  async postLog(
    request: KokattoPostLogRequest
  ): Promise<KokattoPostLogResponse | KokattoErrorResponse> {
    const url = `${this.cisUrl}/logs`;

    const user = getUserDetailFromJwt(this.token);
    const shouldLog = process.env.VUE_APP_CLIENT_IDS_TO_LOG?.includes(
      user.clientId
    );
    if (!shouldLog) {
      console.log(
        "file: KokattoLoggingServiceClient.ts:26 ~ KokattoLoggingServiceClient ~ request:",
        request
      );
      throw new Error(`We dont log client: ${user.clientId}`);
    }
    return this.failsafeHttpRequest(async () => {
      const { type, result } = request;
      let event = request.event;
      try {
        if (process.env.VUE_APP_VERSION) {
          const eventParsed = JSON.parse(event);
          eventParsed.version = process.env.VUE_APP_VERSION;
          event = JSON.stringify(eventParsed);
        }
      } catch (error) {
        console.log(
          "file: KokattoLoggingServiceClient.ts:41 ~ KokattoLoggingServiceClient ~ returnthis.failsafeHttpRequest ~ error:",
          error
        );
      }
      console.log(
        "KokattoLoggingServiceClient ~ returnthis.failsafeHttpRequest ~ request:",
        request
      );
      const response = await axios.post(
        url,
        { type, event, result },
        {
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        }
      );
      return response.data;
    });
  }
}
