import firebaseMessaging from "@/plugins/firebase";
/* eslint-disable no-console */

import { register } from "register-service-worker";
import store from "./store";
import { getUserDetailFromJwt } from "./utils/jwtUtil";
import { KokattoLoggingServiceClient } from "./serviceClients/KokattoLoggingServiceClient";

// if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready(registration) {
    console.warn(
      "[Service Worker] App is being served from cache.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
    store.dispatch("submitLog", {
      payload: { info: "App is being served from cache by a service worker." },
      type: "SERVICE_WORKER",
      isError: false,
    });

    // resets logging data
    const loggingData = {
      shouldLog: process.env.VUE_APP_CLIENT_IDS_TO_LOG?.includes(
        store.getters.getClientId
      ),
      kokattoToken: store.getters.getKokattoTokenAccess,
      cisUrl:
        store.getters.getAccountRegion === "JKT"
          ? process.env.VUE_APP_BASEURL_CIS_AWS_JKT
          : process.env.VUE_APP_BASEURL_CIS_AWS,
    };
    console.warn("Logging data:", loggingData);
    registration.active?.postMessage(JSON.stringify(loggingData));
  },
  registered(registration) {
    console.warn("[Service Worker] Registration successful:", registration);
    console.warn("[Service Worker] Service worker registered");
    console.warn(
      "[Service Worker] Firebase messaging:",
      JSON.parse(JSON.stringify(firebaseMessaging))
    );
    store.dispatch("submitLog", {
      payload: { info: "Service worker has been registered." },
      type: "SERVICE_WORKER",
      isError: false,
    });
  },
  cached() {
    console.warn("[Service Worker] Content cached for offline use");
  },
  updatefound() {
    console.warn("[Service Worker] New content downloading");
  },
  updated(registration) {
    // alert("updated");
    deleteCookies();
    deleteLocalStorage();
    console.warn("[Service Worker] New content available - please refresh");

    // resets logging data
    const loggingData = {
      shouldLog: process.env.VUE_APP_CLIENT_IDS_TO_LOG?.includes(
        store.getters.getClientId
      ),
      kokattoToken: store.getters.getKokattoTokenAccess,
      cisUrl:
        store.getters.getAccountRegion === "JKT"
          ? process.env.VUE_APP_BASEURL_CIS_AWS_JKT
          : process.env.VUE_APP_BASEURL_CIS_AWS,
    };
    console.warn("Logging data:", loggingData);
    registration.active?.postMessage(JSON.stringify(loggingData));
  },
  offline() {
    console.warn(
      "[Service Worker] No internet connection - running in offline mode"
    );
  },
  error(error) {
    console.error("[Service Worker] Registration failed:", error);
    store.dispatch("submitLog", {
      payload: { error, info: "Error during service worker registration:" },
      type: "SERVICE_WORKER",
      isError: true,
    });
  },
});
// }

function deleteCookies() {
  const Cookies = document.cookie.split(";");
  // set 1 Jan, 1970 expiry for every cookies
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
}

function deleteLocalStorage() {
  window.localStorage.clear();
}
