import { IBrandConstants } from "@/models/IBrandConstants";
import { fa } from "vuetify/src/locale";
export const brandConstants: IBrandConstants = {
  PARTNER_CODE: "SMART_SELLER",
  PARTNER_NAME: "Smart Seller",
  PARTNER_NAME_TEXT: "smartchat",
  PARTNER_BRAND_NAME: "SmartChat",
  PARTNER_PREFIX_EMAIL: "ss-",
  DEFAULT_LANGUAGE: "id",

  PARTNER_BILLEDTYPE_CONFIGURABLE: true,
  PARTNER_BILLEDTYPE: "",
  PARTNER_PACKAGABLE: true,

  HAVE_FORGOT_PASSWORD: true,
  HANDLE_FORGOT_PASSWORD: false,
  FORGOT_PASSWORD_LINK: "https://app.smartseller.co.id/password/reset/app",
  HAVE_REGISTER: true,
  HANDLE_REGISTER: false,
  REGISTER_LINK: "https://app.smartseller.co.id/register",
  PARTNER_BUSINESS_EMAIL: "cs@smartseller.co.id",
  PARTNER_CUSTOMER_SERVICE_EMAIL: "cs@smartseller.co.id",
  CREATE_USER_FORM_IS_SHOW_PASSWORD_INPUT_TEXT: false,
  CREATE_USER_FORM_IS_SHOW_EMAIL_INPUT_TEXT: false,
  CREATE_USER_FORM_IS_SHOW_EMAIL_LIST: true,
  CREATE_USER_FORM_IS_SHOW_EMAIL_LABEL: true,
  EDIT_USER_FORM_IS_ROLE_EDITABLE: true,
  CREATE_USER_FORM_IS_SHOW_GROUP_INPUT: false,
  IS_WABA_REQUIRED: false,
  IS_MULTIPLE_PACKAGE_SUBSCRIPTION: false,
  GET_MP_CHANNEL_ALL_SUBSCRIPTION: true,
  IS_BRAZE_ACTIVE: false,
  IS_FBM_ACTIVE: false,
  DEFAULT_REDIRECT_PATH: "/customer-service/inbox",
  MASK_PREMIUM_STATUS_AS: "Premium",
  IS_STATUS_CHIP_OUTLINED: false,
  LOGIN_FORM_SUBMIT_ERROR_RESOURCE: "$vuetify.loginFormSubmitErrorAlt",
  CREATE_USER_FORM_ADD_USER_BANNER_RESOURCE:
    "$vuetify.settingAdminAddSuccessAlt1",
  CREATE_USER_FORM_USER_MAX_LIMIT_BANNER_RESOURCE:
    "$vuetify.settingAdminAddMaxAlt1",
  CREATE_USER_FORM_CS_ABILITY_DESCRIPTION_RESOURCE:
    "$vuetify.settingAdminRoleCSInfoAlt",
  SETTING_ADMIN_USER_ACCESS_LIMIT_RESOURCE: "$vuetify.partnerSettingAdminInfo",
  FREE_ACCOUNT_OVERLAY_RESOURCE: "$vuetify.freeAccountSubscribeAlt1",
  FREE_ACCOUNT_OVERLAY_ACTION_RESOURCE:
    "$vuetify.freeAccountSubscribeActionAlt1",
  // BASE_URL_DEVELOPMENT: "https://devmp.smartchat.co.id",
  BASE_URL_PRODUCTION: "https://smartchat.co.id",
  IS_STORE_INFO_HANDLED: false,
  ALLOWED_MEDIA_PLATFORM: [
    "IGM",
    "MSG",
    "TPD",
    "LZD",
    "SHP",
    "WPU",
    "LNE",
    "TLG",
  ],
  PREFILL_NAME_WITH_EMAIL: false,
  IS_AUTO_SUBCRIBE_AFTER_REGISTRATION: true,
  LOGIN_PAGE_ILLUSTRATION_HEADER_RESOURCE: "$vuetify.emptyString",
  LOGIN_PAGE_ILLUSTRATION_SUBTITLE_RESOURCE: "$vuetify.emptyString",
  CUSTOM_WORDINGS: {
    EN: {
      coachmarkButtonAddAdminSetting:
        "If admins have registered in SmartSeller, you can add them here.",
      emailCreatePreviewDomain: "@smartchat.co.id",
    },
    ID: {
      coachmarkButtonAddAdminSetting:
        "Tambah admin SmartChat, hanya bagi yang terdaftar di SmartSeller.",
      emailCreatePreviewDomain: "@smartchat.co.id",
    },
  },
  SHOW_CHATROOM_SEND_NEW_MESSAGE: false,
  LOGIN_AGREEMENT_LINK:
    "https://koala-asset.s3.ap-southeast-3.amazonaws.com/public/SMART_SELLER/tnc.pdf",
  SHOW_SUPPORTED_BY: true,
  SHOW_NEW_BADGE_UNTIL: {
    menuCSPerformance: 1687712400000,
    menuContact: 1704041999999,
  },
  METABASE_QUESTION_IDS: {
    ID: {
      agentStatusQuestionId: "342",
      chatPerformanceQuestionId: "533",
      analysisQuestionId: "534",
      agentPerformanceQuestionId: "532",
      broadcastReportDashboardQuestionId: "389",
      broadcastReportHistoryQuestionId: "2022",
      broadcastReportHistoryDetailQuestionId: "2023",
      customerSatisfactionQuestionId: "391",
      broadcastHistoryChannelQuestionId: "2437",
      broadcastHistoryBroadcastOtpQuestionId: "2478",
      broadcastHistoryFollowUpQuestionId: "2479",
    },
    EN: {
      agentStatusQuestionId: "383",
      chatPerformanceQuestionId: "533",
      analysisQuestionId: "534",
      agentPerformanceQuestionId: "532",
      broadcastReportDashboardQuestionId: "389",
      broadcastReportHistoryQuestionId: "2022",
      broadcastReportHistoryDetailQuestionId: "2023",
      customerSatisfactionQuestionId: "390",
      broadcastHistoryChannelQuestionId: "2437",
      broadcastHistoryBroadcastOtpQuestionId: "2478",
      broadcastHistoryFollowUpQuestionId: "2479",
    },
  },
  SHOW_CREDIT_BONUS_BANNER: false,
  SHOW_WEBSTORE_DASHBOARD: false,
  SHOW_OPENING_HOUR: false,
  SHOW_DELIVERY_SETTING: false,
  SHOW_BUSINESS_MODE_SETTING: false,
  SETTING_PAGE_PHONE_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactPhoneUpdateAlt1",
  SETTING_PAGE_WABA_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactWABAUpdateAlt1",
  SETTING_PAGE_EMAIL_UPDATE_DESCRIPTION_RESOURCE:
    "$vuetify.storeSettingContactEmailUpdateAlt1",
  PARTNER_ADMIN_LOCKABLE: false,
  SETTING_DASHBOARD_UPGRADE_TITLE_RESOURCE: "$vuetify.dashboardUpgradeTitle",
  SETTING_DASHBOARD_UPGRADE_DESC_RESOURCE:
    "$vuetify.dashboardUpgradeDescription",
  HIDE_DASHBOARD_FEATURE_BUTTON: {
    BROADCAST: false,
    CHATBOT: false,
    CS: false,
  },
  CONNECT_TO_WHATSAPP_UNOFFICIAL: true,
  CONNECT_TO_SHOPEE: true,
  HAS_CONNECTED_CHANNEL_LIMITATION: true,
  CONNECT_TO_VIBER: false,
  SHOW_VARIABLE_LEARN_MORE_BUTTON: true,
  CONNECT_TO_LINE: true,
  CONNECT_TO_TELEGRAM: true,
  SHOW_REPORT_PAGE: false,
  SHOW_CUSTOMER_SATISFACTION_PERFORMANCE: false,
  SHOW_SURVEY_RATING: false,
  SHOW_OUTBOUND_PRICE_ALERT: false,
  API_BASE_URL: {
    production: "https://smartchat.co.id/api/v1",
    development: "https://devmp.smartchat.co.id/api/v1",
  },
  COACHMARK_SHOW_ON_TIER: "free trial",
  COACHMARK_REDIRECT_PATH: "/setting/store?tab=channel",
  SHOW_BROADCAST_REPORT: false,
  NEW_FEATURE_BANNER: false,
  CRM_CUSTOM_FIELD_GUIDELINE_URL: "",
  CONNECT_TO_LIVECHAT: false,
  CAN_SELECT_REGION: false,
  CUSTOMER_SERVICE_LINK: "http://wa.me/6281215000162",
  USER_CONSENT_AGREEMENT_SHOW: false,
  USER_CONSENT_AGREEMENT_LINK:
    "https://koala-asset.s3.ap-southeast-3.amazonaws.com/public/koala_plus_pt_kokatto_teknologi_global.html",
};
